import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/Layout/Layout';
import './our-partners.scss';
import sprite from '../../static/sprite.svg';
import AmazonSmile from '../components/AmazonSmile/AmazonSmile';
import Intro from '../components/Intro/Intro';

const CommunityPartners = ({ data }) => {
  return (
    <Layout>
      <Intro
        heading={data.introText.childTextContentJson.intro.heading}
        description={data.introText.childTextContentJson.intro.description}
        // imageData={data.introImage.childImageSharp.gatsbyImageData}
      />

      <section className="primary-partners">
        {/* <h2 className="primary-partners__heading heading-secondary">
          {
            data.primaryPartnersText.childTextContentJson.primaryPartners
              .heading
          }
        </h2> */}
        <div className="primary-partners__partners">
          {data.primaryPartnersText.childTextContentJson.primaryPartners.description.map(
            (text, index) => (
              <div key={index} className="primary-partners__partner">
                <span className="primary-partners__logo-box">
                  <GatsbyImage
                    image={
                      data.primaryPartnersImages.nodes[index].childImageSharp
                        .gatsbyImageData
                    }
                    alt="partner logo"
                    className={`primary-partners__image primary-partners__image--${
                      index + 1
                    }`}
                  />
                </span>
                <p className="primary-partners__description para">{text}</p>
              </div>
            )
          )}
        </div>
      </section>

      {/* <section className="local-brands">
        <h2 className="local-brands__heading heading-secondary">
          {data.localBrands.childTextContentJson.localBrands.heading1}
        </h2>
        <p className="local-brands__description para">
          {data.localBrands.childTextContentJson.localBrands.description}
        </p>
        <div className="local-brands__lists">
          <ul className="local-brands__list">
            {data.localBrands.childTextContentJson.localBrands.list1.map(
              item => (
                <li className="local-brands__list-item">{item}</li>
              )
            )}
          </ul>
          <ul className="local-brands__list">
            {data.localBrands.childTextContentJson.localBrands.list2.map(
              item => (
                <li className="local-brands__list-item">{item}</li>
              )
            )}
          </ul>
        </div>
        <h2 className="local-brands__heading heading-secondary">
          {data.localBrands.childTextContentJson.localBrands.heading2}
        </h2>
        <ul className="local-brands__list">
          {data.localBrands.childTextContentJson.localBrands.list3.map(item => (
            <li className="local-brands__list-item">{item}</li>
          ))}
        </ul>
        <span className={`local-brands__icon-box`}>
          <svg className="local-brands__icon">
            <use href={`${sprite}#site-graphic`} />
          </svg>
        </span>
      </section> */}
    </Layout>
  );
};

export default CommunityPartners;

export const query = graphql`
  query CommunityPartners {
  introText: file(relativePath: {eq: "our-partners.json"}) {
    childTextContentJson {
      intro {
        heading
        description
      }
    }
  }
  introImage: file(relativePath: { eq: "community-partners.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  primaryPartnersText: file(relativePath: {eq: "our-partners.json"}) {
    childTextContentJson {
      primaryPartners {
        heading
        description
      }
    }
  }
  primaryPartnersImages: allFile(
    filter: {relativePath: {regex: "/partner-\\d/"}}
    sort: {fields: relativePath, order: ASC}
  ) {
    nodes {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
  localBrands: file(relativePath: {eq: "our-partners.json"}) {
    childTextContentJson {
      localBrands {
        description
        heading1
        heading2
        list1
        list2
        list3
      }
    }
  }
}
`;
